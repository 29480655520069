<template>
  <dynamic-menu v-model="model" v-bind="dMenuProps">
    <v-card>
      <v-card-actions class="pl-4 justify-between">
        <div>
          <div v-if="$slots['prepend-head']" class="mr-2">
            <slot name="prepend-head" />
          </div>
          <div>
            <div v-if="title" class="text-subtitle-1 font-weight-bold">
              {{ title }}
            </div>
            <div v-if="subtitle" class="text-lighten-2">{{ subtitle }}</div>
          </div>
          <div v-if="$slots['append-head']" class="ml-2">
            <slot name="append-head" />
          </div>
        </div>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider />
      <v-divider v-if="divider" />
      <v-sheet
        :class="{ 'pa-4': pad }"
        :height="height"
        :max-height="maxHeight"
        class="overflow-y-auto"
      >
        <slot v-bind="{ close }"></slot>
      </v-sheet>
    </v-card>
  </dynamic-menu>
</template>

<script lang="ts">
import { omit, pick } from "lodash";
import { defineComponent } from "vue";
import DynamicMenu, { props } from "./DynamicMenu.vue";

const dMenuPropKeys = Object.keys(
  omit(props, ["modelValue", "height", "maxHeight"])
);

export default defineComponent({
  name: "ActionDialog",
  props: {
    ...props,
    modelValue: Boolean,
    title: String,
    subtitle: String,
    pad: { type: Boolean, default: false },
    divider: { type: Boolean, default: false },
    width: { type: [Number, String], default: 450 },
    height: { type: [Number, String] },
    maxHeight: { type: [Number, String], default: 400 },
  },
  emits: ["update:modelValue"],
  components: { DynamicMenu },
  setup(props, context) {
    const model = computed({
      get(): boolean {
        return props.modelValue;
      },
      set(value: boolean) {
        context.emit("update:modelValue", value);
      },
    });

    const close = () => (model.value = false);

    return {
      close,
      model,
    };
  },
  computed: {
    dMenuProps(): any {
      return pick(this.$props, dMenuPropKeys);
    },
  },
});
</script>
