<template>
  <v-dialog
    v-if="isDesktop"
    v-model="model"
    :is="component"
    :width="width"
    :height="height"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :scrollable="scrollable"
    content-class="dynamic-menu dynamic-menu--desktop"
  >
    <div class="rounded-xl overflow-hidden">
      <slot v-bind="{ model }"></slot>
    </div>
  </v-dialog>
  <v-overlay
    v-else
    v-model="model"
    content-class="dynamic-menu dynamic-menu--mobile "
    close-on-content-click
    close-on-back
  >
    <v-slide-y-reverse-transition>
      <div
        v-if="model"
        @click.stop
        class="rounded-t-xl overflow-hidden"
        style="width: 100vw"
      >
        <slot v-bind="{ model }"></slot>
      </div>
    </v-slide-y-reverse-transition>
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export const props = {
  width: { type: [Number, String], default: 450 },
  height: { type: [Number, String] },
  maxWidth: { type: [Number, String] },
  maxHeight: { type: [Number, String] },
  scrollable: { type: Boolean, default: false },
};

export default defineComponent({
  name: "DynamicMenu",
  props: {
    modelValue: Boolean,
    component: {},
    ...props,
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const breakpoints = useBreakpoints();
    const isDesktop = breakpoints.greaterOrEqual("md");

    const model = computed({
      get(): boolean {
        return props.modelValue;
      },
      set(value: boolean) {
        context.emit("update:modelValue", value);
      },
    });

    return {
      model,
      isDesktop,
    };
  },
});
</script>

<style lang="scss">
.dynamic-menu--mobile.v-overlay__content {
  bottom: 0;
}
</style>
